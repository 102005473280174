import React from 'react';

import { Container, Template } from './home.styled';

const Home = () => {
  return (
    <Container data-testid="home-component">
      <Template className="template-block">
        <div>
          <h3>Ideascore Web</h3>
        </div>
      </Template>
    </Container>
  );
};

export default Home;
